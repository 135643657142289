import React, { useState } from 'react';

function App() {
  const [image, setImage] = useState(null);
  const [agentId, setAgentId] = useState('');
  const [showContent, setShowContent] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAgentIdChange = (event) => {
    setAgentId(event.target.value);
  };

  const handleGeneratePage = () => {
    if (agentId && image) {
      const agentScript = `
        (function() {
          window.VG_CONFIG = {
            ID: "${agentId}",
            region: 'na',
            render: 'popup',
            stylesheets: [
              "https://vg-bunny-cdn.b-cdn.net/vg_live_build/styles.css",
            ],
            autostart: false,
          };
          var VG_SCRIPT = document.createElement("script");
          VG_SCRIPT.src = "https://vg-bunny-cdn.b-cdn.net/vg_live_build/vg_bundle.js";
          VG_SCRIPT.defer = true;
          document.body.appendChild(VG_SCRIPT);
        })();
      `;

      // Inject the JavaScript to initialize TIXAE Agent
      const scriptElement = document.createElement("script");
      scriptElement.innerHTML = agentScript;
      document.body.appendChild(scriptElement);

      // Show the background and agent bubble
      setShowContent(true);
    } else {
      alert('Please enter an AI Agent ID and upload an image.');
    }
  };

  return (
    <div 
      className="App" 
      style={{
        width: '100vw', 
        height: '100vh',
        backgroundImage: showContent && image ? `url(${image})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent'
      }}
    >
      {/* Input Form */}
      {!showContent && (
        <div 
          style={{
            width: '300px', // Set a smaller width for the form
            textAlign: 'center', 
            backgroundColor: 'rgba(255, 255, 255, 0.9)', 
            padding: '20px', 
            borderRadius: '8px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            margin: 'auto', // Center the form horizontally
            marginTop: '10vh' // Center the form vertically with some spacing from the top
          }}
        >
          <h3>Please input the ID of the Tixae Agent</h3>
          <input
            type="text"
            placeholder="Enter AI Agent ID"
            value={agentId}
            onChange={handleAgentIdChange}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />
          <h3>Upload the image to be shown</h3>
          <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginBottom: '10px', width: '100%' }} />
          <button onClick={handleGeneratePage} style={{ padding: '10px 20px', width: '100%' }}>
            Generate
          </button>
        </div>
      )}

      {/* TIXAE Agent Bubble */}
      {showContent && (
        <div 
          style={{
            position: 'fixed', 
            bottom: '20px', 
            left: '20px', 
            width: 'auto', 
            height: 'auto',
            backgroundColor: 'transparent'
          }} 
          id="VG_OVERLAY_CONTAINER"
        >
          {/* Placeholder for TIXAE Agent widget */}
        </div>
      )}
    </div>
  );
}

export default App;